import { useCallback } from 'react';
import { useApplicationContext } from '../useApplicationContext';

export enum TimezoneFormatType {
  NAME = 'name`',
  OFFSET = 'offset',
}

export const useFormatters = () => {
  const { selectedTimezone, businessInfo } = useApplicationContext();
  const { timezone } = selectedTimezone || {};
  const { dateRegionalSettingsLocale } = businessInfo || {};

  const createIntl = (options?: Intl.DateTimeFormatOptions) => {
    return new Intl.DateTimeFormat(dateRegionalSettingsLocale, {
      timeZone: timezone,
      ...options,
    });
  };

  const getStartTime = useCallback(
    (start: Date) => {
      return createIntl({ hour: 'numeric', minute: 'numeric' }).format(start);
    },
    [timezone, dateRegionalSettingsLocale],
  );

  const getStartDate = useCallback(
    (start: Date) => {
      return createIntl({
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      }).format(start);
    },
    [timezone, dateRegionalSettingsLocale],
  );

  const getTimezoneName = useCallback(
    (timeZoneFormatType: TimezoneFormatType, overrideTimezone?: string) => {
      const options = {
        year: 'numeric',
        timeZoneName:
          timeZoneFormatType === TimezoneFormatType.NAME ? 'long' : 'short',
        ...(overrideTimezone ? { timeZone: overrideTimezone } : {}),
      } as Intl.DateTimeFormatOptions;

      return createIntl(options)
        .formatToParts(new Date())
        .find(({ type }) => type === 'timeZoneName')?.value;
    },
    [timezone, dateRegionalSettingsLocale],
  );

  return { getStartTime, getStartDate, getTimezoneName };
};
