import React, { FC } from 'react';
import { BookingsGroup } from '../../../../../../types';
import { useFormatters } from '../../../../../../core/hooks/useFormatters';
import Text, { TextType } from '../../../../Components/Text';

export interface BookingsDetailsProps {
  group: BookingsGroup;
}

export const BookingsDetails: FC<BookingsDetailsProps> = ({ group }) => {
  const { bookings } = group;
  const firstBooking = bookings[0];
  const { getStartDate, getStartTime } = useFormatters();
  return (
    <Text type={TextType.Secondary} tagName="p">
      {getStartDate(new Date(firstBooking.start))}{' '}
      {getStartTime(new Date(firstBooking.start))}
    </Text>
  );
};
