import {
  StyleParamType,
  wixColorParam,
  wixFontParam,
  createStylesParams,
} from '@wix/tpa-settings';

export type IStylesParams = {
  memberAreaDesignTitleColor: StyleParamType.Color;
  memberAreaDesignTitleFont: StyleParamType.Font;
  memberAreaDesignTitleMobileFontSize: StyleParamType.Number;
  memberAreaDesignDescriptionColor: StyleParamType.Color;
  memberAreaDesignDescriptionFont: StyleParamType.Font;
  memberAreaDesignDescriptionMobileFontSize: StyleParamType.Number;
  memberAreaDesignTextColor: StyleParamType.Color;
  memberAreaDesignTextFont: StyleParamType.Font;
  memberAreaDesignTextMobileFontSize: StyleParamType.Number;
  memberAreaDesignSecondaryTextColor: StyleParamType.Color;
  memberAreaDesignSecondaryTextFont: StyleParamType.Font;
  memberAreaDesignSecondaryTextMobileFontSize: StyleParamType.Number;
  memberAreaDesignLinksColor: StyleParamType.Color;
  memberAreaDesignLinksFont: StyleParamType.Font;
  memberAreaDesignLinkButtonTextColor: StyleParamType.Color;
  memberAreaDesignLinkButtonColor: StyleParamType.Color;
  memberAreaDesignLinkButtonBorderColor: StyleParamType.Color;
  memberAreaDesignLinkButtonBorderWidth: StyleParamType.Number;
  memberAreaDesignLinkButtonBorderRadius: StyleParamType.Number;
  memberAreaDesignLinksMobileFontSize: StyleParamType.Number;
  memberAreaDesignSelectedTabBorderColor: StyleParamType.Color;
  memberAreaDesignTabsBorderColor: StyleParamType.Color;
};

export default createStylesParams<IStylesParams>({
  memberAreaDesignTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  memberAreaDesignTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Page-title', {
      size: 28,
    }),
  },
  memberAreaDesignTitleMobileFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
  },
  memberAreaDesignDescriptionColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  memberAreaDesignDescriptionFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  memberAreaDesignDescriptionMobileFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  memberAreaDesignTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  memberAreaDesignTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 14,
    }),
  },
  memberAreaDesignTextMobileFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  memberAreaDesignSecondaryTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-4'),
  },
  memberAreaDesignSecondaryTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 14,
    }),
  },
  memberAreaDesignSecondaryTextMobileFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  memberAreaDesignLinksColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  memberAreaDesignLinksFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  memberAreaDesignLinkButtonColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  memberAreaDesignLinkButtonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  memberAreaDesignLinkButtonBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  memberAreaDesignLinkButtonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  memberAreaDesignLinkButtonBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  memberAreaDesignLinksMobileFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  memberAreaDesignSelectedTabBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  memberAreaDesignTabsBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
});
