import React, { FC } from 'react';
import { useApplicationContext } from '../../../../../core/hooks/useApplicationContext';
import { TabTypes } from '../../../../../types';
import { BookingsDetails } from './BookingsDetails';
import { BookingsListDataHooks } from '../../datahooks';

export const BookingsList: FC = () => {
  const { upcomingData, historyData, selectedTab } = useApplicationContext();

  const data =
    selectedTab.type === TabTypes.UPCOMING ? upcomingData : historyData;

  if (!data) {
    return null;
  }

  return (
    <div data-hook={BookingsListDataHooks.Main}>
      {data.groups.map((group) => (
        <div
          data-hook={BookingsListDataHooks.BookingsDetailsWrapper}
          key={group.bookings[0].bookingId}
        >
          <BookingsDetails group={group} />
        </div>
      ))}
    </div>
  );
};
