export enum WidgetDataHooks {
  MyBookingsMainWidget = 'my-bookings-widget',
}

export enum HeaderDataHooks {
  Main = 'header-main',
  PageTitle = 'page-title',
  PageSubtitle = 'page-subtitle',
}

export enum TimezoneDropdownDataHooks {
  Main = 'timezone-dropdown-main',
  Dropdown = 'timezone-dropdown',
  TimezoneText = 'timezone-text',
}

export enum BookingsLayoutDataHooks {
  Main = 'bookings-layout-main',
  TabbedContent = 'bookings-layout-tabbed-content',
  Spinner = 'bookings-layout-spinner',
}

export enum EmptyStateDataHooks {
  Main = 'empty-state-main',
  Text = 'empty-state-title',
  Button = 'empty-state-button',
}

export enum BookingsListDataHooks {
  Main = 'bookings-list-main',
  BookingsDetailsWrapper = 'bookings-list-bookings-details-wrapper',
}
